import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { Amplify, Auth } from 'aws-amplify'
import awsconfig from '@/src/aws-exports'

export default defineNuxtPlugin(() => {
  Amplify.configure(awsconfig)
  return {
    // name: 'aws-amplify',
    // parallel: true,
    provide: {
      amplify: {
        signIn: async (email: string, password: string) => {
          try {
            const { user } = await Auth.signIn(email, password)
            console.log('user', user)
          } catch (error) {
            console.log('sign in error', error)
          }
        },
        signUp: async (email: string, password: string) => {
          try {
            console.log('sign up - plugin')
            const { user } = await Auth.signUp({
              username: email,
              password
            })
            console.log('user', user)
          } catch (error) {
            console.log('sign up error', error)
          }
        }
      }
    }
  }
})
