export const appKeepalive = true

export const appLayoutTransition = {"name":"layout","mode":"out-in","duration":30}

export const appPageTransition = {"name":"page","mode":"out-in","duration":30}

export const appHead = {"meta":[{"name":"description","content":"Cash and Goal canvas"},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"content":"IE=edge,chrome=1","http-equiv":"X-UA-Compatible"}],"link":[],"style":[],"script":[{"innerHTML":"window.process = window.process || { env: { NODE_DEBUG: undefined, DEBUG: undefined } }; window.global = window;"}],"noscript":[],"viewport":"width=device-width, initial-scale=1, viewport-fit=cover","bodyAttrs":{"class":"relative overflow-x-hidden"},"htmlAttrs":{"lang":"en"},"title":"Cash Studio"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null