import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as account_45oldFQvxJfakgTMeta } from "/vercel/path0/pages/account-old.vue?macro=true";
import { default as _91action_93HadD7zOX22Meta } from "/vercel/path0/pages/account/[action].vue?macro=true";
import { default as signin2QqhlmsNUCMeta } from "/vercel/path0/pages/account/signin.vue?macro=true";
import { default as signupXAxOgvkxiuMeta } from "/vercel/path0/pages/account/signup.vue?macro=true";
import { default as indexWk8Hi46KRAMeta } from "/vercel/path0/pages/app/index.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as terms_45and_45conditions0ynQwIk1xRMeta } from "/vercel/path0/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: account_45oldFQvxJfakgTMeta?.name ?? "account-old",
    path: account_45oldFQvxJfakgTMeta?.path ?? "/account-old",
    meta: account_45oldFQvxJfakgTMeta || {},
    alias: account_45oldFQvxJfakgTMeta?.alias || [],
    redirect: account_45oldFQvxJfakgTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account-old.vue").then(m => m.default || m)
  },
  {
    name: _91action_93HadD7zOX22Meta?.name ?? "account-action",
    path: _91action_93HadD7zOX22Meta?.path ?? "/account/:action()",
    meta: _91action_93HadD7zOX22Meta || {},
    alias: _91action_93HadD7zOX22Meta?.alias || [],
    redirect: _91action_93HadD7zOX22Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/[action].vue").then(m => m.default || m)
  },
  {
    name: signin2QqhlmsNUCMeta?.name ?? "account-signin",
    path: signin2QqhlmsNUCMeta?.path ?? "/account/signin",
    meta: signin2QqhlmsNUCMeta || {},
    alias: signin2QqhlmsNUCMeta?.alias || [],
    redirect: signin2QqhlmsNUCMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/signin.vue").then(m => m.default || m)
  },
  {
    name: signupXAxOgvkxiuMeta?.name ?? "account-signup",
    path: signupXAxOgvkxiuMeta?.path ?? "/account/signup",
    meta: signupXAxOgvkxiuMeta || {},
    alias: signupXAxOgvkxiuMeta?.alias || [],
    redirect: signupXAxOgvkxiuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/signup.vue").then(m => m.default || m)
  },
  {
    name: indexWk8Hi46KRAMeta?.name ?? "app",
    path: indexWk8Hi46KRAMeta?.path ?? "/app",
    meta: indexWk8Hi46KRAMeta || {},
    alias: indexWk8Hi46KRAMeta?.alias || [],
    redirect: indexWk8Hi46KRAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: contactJnsDpYVejzMeta?.name ?? "contact",
    path: contactJnsDpYVejzMeta?.path ?? "/contact",
    meta: contactJnsDpYVejzMeta || {},
    alias: contactJnsDpYVejzMeta?.alias || [],
    redirect: contactJnsDpYVejzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45conditions0ynQwIk1xRMeta?.name ?? "terms-and-conditions",
    path: terms_45and_45conditions0ynQwIk1xRMeta?.path ?? "/terms-and-conditions",
    meta: terms_45and_45conditions0ynQwIk1xRMeta || {},
    alias: terms_45and_45conditions0ynQwIk1xRMeta?.alias || [],
    redirect: terms_45and_45conditions0ynQwIk1xRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/terms-and-conditions.vue").then(m => m.default || m)
  }
]