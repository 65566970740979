import revive_payload_client_woPUqdU0c0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.5.1_eslint@8.47.0_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_Iuh6GUzjOK from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.5.1_eslint@8.47.0_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_7zNmuBaWmG from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.6_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_mNWPWTDUxp from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.5.1_eslint@8.47.0_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_FQ4ltCDqmv from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.5.1_eslint@8.47.0_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_LOayNoMOc8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.5.1_eslint@8.47.0_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Und4WKVdZj from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.1.1_@pinia+nuxt@0.4.11_pinia@2.1.6/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import aws_amplify_client_vGnYEC6dfx from "/vercel/path0/plugins/aws-amplify.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_woPUqdU0c0,
  router_Iuh6GUzjOK,
  plugin_vue3_7zNmuBaWmG,
  components_plugin_KR1HBZs4kY,
  unhead_mNWPWTDUxp,
  prefetch_client_FQ4ltCDqmv,
  chunk_reload_client_LOayNoMOc8,
  plugin_Und4WKVdZj,
  aws_amplify_client_vGnYEC6dfx,
  sentry_client_shVUlIjFLk
]