import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import { isDevelopment } from 'std-env'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const release = `${config.public.SENTRY_PROJECT}-client@${config.public.COMMIT_REF}`
  const environment = String(config.public.ENV) || 'dev'

  Sentry.init({
    enabled: Boolean(config.public.SENTRY_ENABLED) || true,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: false, // isDevelopment,
    dsn: String(config.public.SENTRY_DSN) || '',
    release,
    environment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router as Router),
        tracePropagationTargets: [String(config.public.SENTRY_TRACE_PROPAGATION_TARGET) || '']
      }),
      new Sentry.Replay()
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 1,
    sampleRate: 1,
    tracesSampleRate: 1
  })
})
